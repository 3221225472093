

module.exports = {
    en : {
       'german-bundesliga-8' : `The Bundesliga, often known as the Fußball-Bundesliga or the 1. Bundesliga, is a German professional association football competition. The Bundesliga is Germany's premier football tournament, and it is located at the top of the German football league structure. With the 2. Bundesliga, it consists of 18 clubs and works on a promotion and relegation system. From August through May, the seasons are in effect. The majority of games take place on Weekends, with a few games taking place throughout the week. The DFB-Pokal is open to all Bundesliga teams. The Bundesliga champion advances to the DFL-Supercup. 
                        Since its inception, the Bundesliga has included fifty-six clubs. Bayern Munich has claimed the Bundesliga title 30 times, which is the most of any team in the league. Other champions have emerged in the Bundesliga, including Borussia Dortmund, Borussia Mönchengladbach, Werder Bremen, Hamburger SV, and VfB Stuttgart. According to UEFA's league index rating for the 2020–21 season, based on results in European tournaments over the previous five seasons, the Bundesliga is one of the best national leagues in Europe, ranking fourth. From 1976 until 1984, and again in 1990, the Bundesliga topped the UEFA rankings. 
                        Six times, it has produced the region's top-rated club. Bundesliga teams have won the UEFA Champions League eight times, the UEFA Europa League six times, the European Cup Winners' Cup four times, the UEFA Super Cup twice, the FIFA Club World Cup twice, and the Intercontinental Cup three times. Its players have won nine Ballon d'Or honors, one Best FIFA Men's Player award, three European Golden Shoe awards, and three UEFA Men's Player of the Year trophies, including UEFA Club Footballer of the Year. 
                        The Bundesliga is the world's most popular football league in terms of average attendance. Its average of 45,134 spectators per game during the 2011–12 season was the second-highest of any sports team in the country, trailing only the American National Football League. The Bundesliga is aired in over 200 countries across the world. 
                        The Bundesliga was created in Dortmund in 1962, and the inaugural season began in 1963. The Bundesliga's structure and organization, like that of Germany's other football leagues, have experienced repeated modifications. The Bundesliga was created by the Deutscher Fußball-Bund, which translates to German Football Association in English, but is currently run by the Deutsche Fußball Liga (German Football League in English). 
                        The German football champion is determined solely by the results of the Bundesliga. Every club plays every other club twice, once at home and once on the away. Traditionally, a victory was worth two points, a tie was for one point, and a defeat was worth none. Since the 1995–96 season, a win has been worth three points, a draw is for one point, and a defeat is worth zero points. At the end of the season, the club with the most points wins the German championship. Presently, the top four clubs in the league are automatically qualified for the UEFA Champions League group stage. 
                        The bottom two clubs are demoted to the 2. Bundesliga, while the top two teams in the 2. Bundesliga advanced. The 16th and third-placed teams in the 2. Bundesliga competes in a two-leg play-off competition. The winner of this match will play in the Bundesliga the next season, while the loser will play in the 2. Bundesliga. If two teams are tied on points, the standard tie-breakers are being used: goal difference and total goals scored for the season, head-to-head results (which means total points), head-to-head goals scored, head-to-head away goals scored, total away goals scored for the season. 
                        If two clubs are still tied after applying all of these tie-breakers, a single match at a neutral location is required to decide the ranking. However, in the history of the Bundesliga, this has never been required. 
                        In terms of team composition, matchday teams must include no more than five non-EU players. There are nine substitutions available, three of which can be utilized for the entirety of the game.
                        `,
        'english-premier-league-36' :`The Premier League, often known as the EPL or English Premier League, is the highest level of English football competitive matches. It is competed by 20 clubs and functions on a relegation and promotion system similar to the English Football League (EFL). Each club plays 38 matches during the season, which runs from August to May. The majority of the games are held on Saturday and Sunday afternoons. 
                            The FA Premier League was established on February 20, 1992, when clubs in the Football League First Division decided to break away from the league to reap the benefits of a substantial television rights agreement. As of 2013 to 2014, the domestic agreement was valued at about £1 billion per year, with Sky and BT Group gaining domestic broadcast rights 116 and 38 games, respectively. The league is a company with member teams as stockholders, generating €2.2 billion in regional and abroad television rights each year. In 2016-2017, clubs received £2.4 billion in central payment income, plus £343 million in unity payments to English Football League (EFL) teams. 
                            The Premier League is the most popular sport in the world, with 643 million households and potential TV viewership of 4.7 billion people watching it in 212 countries. The average Premier League match attendance for the 2018–2019 season was 38,181, second only to the Bundesliga's 43,500, while cumulative attendance across all matches was 14,508,981, the most of any league. The majority of stadiums are nearly full. As of 2021, the Premier League is ranked first in the UEFA rankings, which are based on league achievements in European tournaments over the previous five seasons. With five English teams have won a total of fourteen European trophies, the English Premier League has the second-highest number of UEFA Champions League/European Cup victories. 
                            The Premier League is made up of 20 teams. Each team plays the other twice throughout the season, usually from August to May, for a total of 38 games, once at their home stadium and another at their rivals'. A win earns three points, while a tie earns one point. A defeat does not result in any points being given. Total points, goal differential, and goals scored are used to rank teams. Teams are regarded to be in the same position if they are still equal. A play-off match at a neutral site determines rank if there is a tie for the title, relegation, or qualifying to other tournaments. 
                            Between the Premier League and the EFL Championship, there is a promotion and relegation system. The Premier League's three lowest-placed teams are demoted to the Championship, while the Championship's top two teams are promoted to the Premier League, with a third team advanced following a series of play-offs including the third, fourth, fifth, and sixth-placed clubs. In 1995, four teams were demoted from the league and just two were promoted, decreasing the number of clubs from 22 to 20. At the start of the 1991–92 season, the top flight had just been extended to 22 clubs, a year before the Premier League was formed.
                            `,
        'spanish-la-liga-31' :`The Campeonato Nacional de Liga de Primera División, or simply Primera División in Spain and La Liga in English-speaking nations, is the Spanish football league system's highest professional football division for men. The Liga Liga Nacional de Fútbol Profesional oversees the competition, which has 20 clubs. At the end of each season, the three lowest-placed teams are relegated to the Segunda División, where they are replaced by the top two teams and a play-off winner. 
                    Since its establishment, La Liga has been home to a total of 62 teams. Real Madrid has won the title 34 times, and Barcelona has won the title 26 times. Valencia, Atlético Madrid, and Barcelona were the most successful clubs in the 1940s, winning numerous titles. In the 1950s, Real Madrid and Barcelona dominated La Liga, winning four titles apiece. Real Madrid dominated La Liga in the 1960s and 1970s, winning 14 championships compared to four for Atlético Madrid. Real Madrid dominated La Liga in the 1980s and 1990s, while the Basque rivals Athletic Club and Real Sociedad also enjoyed success, winning two Liga titles respectively. Barcelona has ruled La Liga during the 1990s, winning 16 titles to date. Although Real Madrid has been the most successful club in La Liga, other winners have included Atlético Madrid, Valencia, and Deportivo La Coruña. 
                    With a total crowd of 26,933 for league matches in the 2018–19 season, La Liga is among the most popular pro football leagues in the world. This is the eighth-highest domestic pro football league in the world, and the third-highest professional football association league in the world, after the Bundesliga and the Premier League, but ahead of the other two ""Big Five"" European leagues, Serie A and Ligue 1. After the NFL, MLB, NBA, Premier League, and NHL, La Liga is the world's sixth-richest professional sports league in terms of income. 
                    La Liga was the top league in Europe for each of the seven years from 2013 to 2019 and has topped Europe for 22 of the 60 rated years up to 2019, more than any other country. This has produced the region's top-rated club more times (22) than any other league during that period, more than double the number of times as second-placed Serie A (Italy), such as the top club in 10 of the 11 seasons between 2009 and 2019. Each of these milestones was accomplished by one of two teams: Barcelona or Real Madrid. 
                    The tournament is conducted in the traditional double round-robin style. Each team plays every other club twice, once at home and once away, for a total of 38 matches throughout the season, which runs from August to May. A win earns three points, a tie earns one point, while a defeat earns no points. Teams are ranked based on their total points, with the club with the highest point total at the end of the season being declared champion. 
                    Between the Primera División and the Segunda División, there is a promotion and relegation system. The top two teams from the Segunda División are admitted to La Liga, with an extra club promoted following a set of play-offs featuring the third, fourth, fifth, and sixth-ranked clubs.           
                    `,
        'france-ligue-1-11' :`Ligue 1, formally known as Ligue 1, is a professional men's association football league in France. It is the country's premier football tournament and is placed at the top of the French football tournament format. Ligue 1 is competed by 20 clubs and works on a promotion and relegation system from and to Ligue 2. It is administered by the Ligue de Football Professionnel. 
                    From August through May, the seasons are in effect. Over the course of the season, clubs play two matches against each of the other teams in the league, once at home and once away, for a total of 38 matches. The majority of games take place on Saturdays and Sundays, with a few games taking place on weeknights. Play is usually halted for two weeks the weekend before Christmas and resumed in the second week of January. As of 2018, Ligue 1 is one of Europe's major national leagues, ranking fifth after the Premier League of England, La Liga of Spain, Bundesliga of Germany, and Serie A of Italy. 
                    Ligue 1 began on September 11, 1932, under the name National, before changing its name to Division 1 after a year. It operated under that moniker until 2002 when it changed its name to its present one. With 10 league wins, AS Saint-Étienne is the most successful team in France, while Olympique Lyonnais has won the most consecutive titles with seven titles between 2002 and 2008. 
                    With 71 seasons in Ligue 1, Olympique de Marseille holds the record for most seasons among the best, while Paris Saint-Germain holds the record for league longevity with 47 seasons. Lille is the titleholder, having won the 2020–21 season. The league has been won on several occasions by AS Monaco, a foreign-based team whose participation in the tournament makes it a cross-border tournament. 
                    In Ligue 1, there are a total of 20 clubs. Each club plays each other twice during the course of the season, usually from August to May, once at their home field and once at their opponents', for a total of 38 games, though special conditions may allow a club to host tournaments at other locations, as when Lille headlined Lyon at the Stade de France in 2007 and 2008. A win earns three points, while a tie earns one point. A defeat does not result in any points being given. Total points, goal differential, and goals scored are used to rank teams. The team with the most points at the conclusion of the season is declared the winner. If the points are tied, the winner is determined by the goal difference then the goals scored. 
                    Teams are regarded to be in the same position if they are still equal. A play-off match at a neutral site determines rank if there is a tie for the title, relegation, or qualifying to other tournaments. Only two clubs from Ligue 2 were to be demoted and only two teams from Ligue 1 were to be admitted for the 2015–16 season, but this decision was reversed, and three teams were demoted and three teams promoted. Thus, in the 2016–17 season, a relegation play-off between the 18th-placed Ligue 1 team and the 3rd-placed Ligue 2 team was reinstated as a two-legged competition, with the Ligue 2 team home the first game. 
                    The league previously used a separate promotion and relegation system. Before 1995, the league's system was similar to the Dutch Eredivisie and the German Bundesliga, with immediate relegation of the bottom two clubs and a play-off between the third-last first-division team and the winner of the second-division play-offs. A ""bonus"" rule has also been tried out by the league. From 1973 to 1976, a regulation was in place that awarded one additional point to teams scoring three or more goals in a game, regardless of what happens, with the purpose of promoting offensive play. In the end, the experience was ambiguous. 
                    The top three clubs in Ligue 1 qualify for the Champions League, with the top two going straight to the group stage, as decided by the UEFA coefficient for the 2017–18 season. The third-placed team advances to the third round of qualification. The team that finishes fourth qualifies for the UEFA Europa League. The remaining two Europa League spots are selected by the Coupe de France and Coupe de la Ligue, the country's two domestic league tournaments. The fifth and sixth-placed teams in Ligue 1 will compete for the Europa League if both cup winners advance through their league position.
                    `,
        'italian-serie-a-34' : `Serie A, commonly known as Serie A TIM because of TIM's sponsorship, is a professional league tournament for football teams at the top of the Italian football league system, with the winner receiving the Scudetto and the Coppa Campioni d'Italia. It has been a round-robin competition for almost ninety years, beginning with the 1929–30 season. It was run by the Direttorio Divisioni Superiori until 1943, and then by the Lega Calcio until 2010 when the Lega Serie A was established for the 2010–11 season. Serie A is widely considered as one of the world's greatest football leagues, and it is frequently portrayed as the most tactically and defensive-minded national league. According to IFFHS, Serie A was the world's strongest national league in 2020, and it is placed 3rd among European leagues according to UEFA's league coefficient, trailing La Liga and the Premier League but ahead of the Bundesliga and Ligue 1. The league coefficient is measured by the performance of Italian clubs in the Champions League and the Europa League over the past five years. From 1986 to 1988, and again from 1990 to 1999, Serie A topped the UEFA rankings. 
                    From the 1929–30 season forward, the Italian Football Championship was changed from region and inter-regional stages to a single-tier league. FIGC officially recognizes championship titles won prior to 1929 with the same weighting as championships given thereafter. Similarly, although if its title is entirely official, the 1945–46 season, when the round-robin was stopped and the league was split into two geographical groups owing to the devastation of WWII, is not statistically evaluated. 
                    The league is home to three of the world's most famous clubs, Juventus, Milan, and Internazionale, who were all founding members of the G-14, a group that symbolized the largest and most influential European football clubs from 2000 to 2008, with the first two also original founders of its successor organization, the European Club Association (ECA). More players have won the prestigious Ballon d'Or prize while playing for a Serie A team than any other league in the world, with the exception of Spain's La Liga, which has the most overall Ballon d'Or winners. In addition, the club is the only one in the world to have won every recognized confederation competition. With eighteen recognized international championships, Milan is tied for third in the world. Internazionale has become the first Italian team to win the treble after their success in the 2009–10 season. Inter is the only club in Italian football history that has never been relegated. Juventus, Milan, and Inter are regarded as the Seven Sisters of Italian football, along with Lazio, Fiorentina, Roma, and Napoli. 
                    Serie A is one of the world's most illustrious football leagues. Serie A has produced 42 of the 100 best footballers in history, more than any other league in the world, according to FourFourTwo magazine. Juventus has delivered the most World Cup champions (25), with Inter (19), Roma (15), and Milan (10) placing third, fourth, and ninth in that order, respectively. 
                    For the majority of Serie A's existence, 16 or 18 clubs competed at the highest level. However, there have been a total of 20 clubs since 2004–05. For political reasons, after post-war conflicts with Yugoslavia, one season (1947–48) was played with 21 teams. During the season, which spans from August to May, each club plays each one of the clubs two times; once at home and once away, for a total of 38 games for each team at the finish of the season. Thus, a real round-robin structure is employed in Italian football. Each team plays once against each league opponent for a total of 19 games in the first half of the season, known as the “andata”. The clubs play in the same sequence as they did at the start of the season, with the exception that home and away scenarios are reversed in the second part of the season, known as the “ritorno”. Teams have been awarded three points for a victory, one point for a tie, and no points for a defeat since the 1994–95 season. 
                    The top four clubs in Serie A are automatically qualified for the UEFA Champions League. The fifth-placed team, together with the Coppa Italia champion, qualified for the UEFA Europa League campaign. Depending on the success of the Coppa Italia winner, the sixth or seventh-ranked team advances to the UEFA Europa Conference League preliminary round. The worst three teams in the standings are demoted to Serie B. 
                    If two or more clubs are tied in points after the 2005–06 season, the determining tie-breakers are head-to-head records, goal difference of head-to-head games, goal difference overall, higher number of goals scored, and draw. 
                    If the two clubs were deadlocked on points, a playoff was used to choose the champions, European places, or relegation until the 2004–05 season. Any play-offs were conducted after the main season ended. Bologna and Inter ended with 54 points in the 1963–64 season, the last time a title playoff was held. Bologna won the play-off by a score of 2–0.
                    `,
    },
    th : {
        'german-bundesliga-8' :`บุนเดสลีกา เยอรมัน - ก่อตั้งเมื่อ ค.ศ. 1963 อยู่ภายใต้การบริหารของสมาคมฟุตบอลเยอรมัน บุนเดิสลีกาเป็นการแข่งขันฟุตบอลในระดับลีกสูงสุดของเยอรมนี บุนเดิสลีกาประกอบด้วยทีม 18 ทีม โดย4ทีมที่ดีที่สุดจะได้รับสิทธิ์เข้าไปเล่นในยูฟ่าแชมเปียนส์ลีก ส่วนอันดับ 16-18จะตกชั้นไปเตะบุนเดิสลีกา 2 นับตั้งแต่ก่อตั้งทีมที่คว้าแชมป์สูงสุดคือ บาเยิร์น มิวนิค 29สมัย  โบรุสเซีย ดอร์ทมุนด์ และ โบรุสเซีย เมินเชินกลัทบัค คว้าทีมละ 5 สมัย`,
        'english-premier-league-36' :'พรีเมียร์ลีกอังกฤษ – เป็นการแข่งขันฟุตบอลในระดับลีกสูงสุดของประเทศอังกฤษและเป็นลีกที่ได้รับความนิยมสูงสุดของโลก โดยแข่งขันกัน 20 สโมสร แต่ละสโมสรลงเล่นทั้งหมด 38 นัดจากการพบกันเหย้าและเยือน โดยนัดการแข่งขันส่วนใหญ่มักจะแข่งขันในช่วงบ่ายวันเสาร์และวันอาทิตย์ พรีเมียร์ลีกเริ่มก่อตั้ง เมื่อวันที่ 20 กุมภาพันธ์ ค.ศ. 1992 หลังจากฟุตบอลลีกเฟิสต์ดิวิชัน ต้องการแยกตัวกับทางอิงกลิชฟุตบอลลีก ที่ก่อตั้งมาตั้งแต่ ค.ศ 1888 ตั้งแต่เริ่มก่อตั้ง มี7 สโมสรจากทั้งหมดที่ชนะเลิศพรีเมียร์ลีก ได้แก่  แมนเชสเตอร์ยูไนเต็ด (13), เชลซี (5), แมนเชสเตอร์ซิตี (5), อาร์เซนอล (3) ตามด้วย แบล็กเบิร์นโรเวอส์ เลสเตอร์ซิตี และ ลิเวอร์พูล (ทีมละ1สมัย)',
        'spanish-la-liga-31': `ลาลีกา สเปน - เป็นการแข่งขันฟุตบอลในระดับลีกสูงสุดลีกฟุตบอลอาชีพของประเทศสเปน หรือเป็นที่รู้จักกันทั่วไปในชื่อ ลาลิกา (La Liga) แข่งขันกัน 20 สโมสร หลักๆมี 3สโมสรใหญ่อย่าง เรอัล มาดริด บาร์เซโลนา และ อัตเลติโกเดมาดริด ที่มีฐานแฟนบอลทั่วโลก ลาลิกาได้รับการยอมรับว่าเป็นลีกฟุตบอลที่เข้มข้นที่สุดแห่งหนึ่งในทวีปยุโรป ก่อตั้งเมื่อปี ค.ศ 1929`,
        'france-ligue-1-11' :`ลีกเอิง ฝรั่งเศส/Ligue1-  เป็นลีกฟุตบอลสูงสุดของประเทศฝรั่งเศส โดยมีลีกรองลงมาคือ ลีกเดอ (Ligue 2) ก่อตั้งในปี พ.ศ. 2475 (ค.ศ. 1932) อยู่ภายใต้การบริหารของสหพันธ์ฟุตบอลฝรั่งเศส จบฤดูกาล 2ทีมอันดับสุดท้ายของตารางจะตกชั้นไปเล่นในลีกเดอ และทีม2อันดับแรกจากลีกเดอจะเลื่อนขึ้นมา เสน่ห์อีกอย่างของลีกเอิงคือทีมอันดับ 18 จากลีกเอิงจะต้องไปตัดสินรอดตกชั้นกับทีมอันดับ 3-6 ของลีกเดอที่ชนะเลิศเพย์ออฟขึ้นมา ทีมใดมีผลคะแนนเหย้าเยือนมากกว่า จะได้เลื่อนชั้นหรืออยู่บนลีกสูงสุดต่อไป แซ็งเตเตียน และ มาร์เซย์ เป็นทีมที่คว้าแชมป์ได้มากที่สุด คือ 10สมัยเท่ากัน ส่วนปารีส แซงต์-แชร์กแมง 9สมัย`,
        'italian-serie-a-34' :`เซเรียอา อิตาลี - ลีกฟุตบอลสูงสุดของประเทศอิตาลี ที่เริ่มทำการแข่งขันกันมาอย่างยาวนานตั้งแต่ปี ค.ศ. 1898 อยู่ภายใต้การบริหารของสหพันธ์ฟุตบอลอิตาลี  ยูเวนตุสเป็นทีมที่ประสบความสำเร็จมากที่สุด โดยได้แชมป์ไปถึง 36 สมัย รองลงมาเป็นเอซี มิลาน กับอินเตอร์มิลานที่ได้แชมป์ 18 สมัยเท่ากัน แข่งขันกัน 20 สโมสร จบฤดูกาล 4 ทีมที่อันดับดีสุดจะได้ผ่านเข้าไปเล่นในยูฟ่าแชมเปียนส์ลีก ส่วน 3 ทีมอันดับสุดท้ายของตารางจะตกชั้นลงในเล่นในเซเรียบี และ3ทีมจากเซเรียบีจะเลื่อนชั้นขึ้นมาสู่ เซเรียอา`,
    }
}