<template>
    <div class="" v-if="!isMobile" v-show="!isLoading && !isShowNoData">
        <div class="match-wrapper " v-for="(level1, index) in matchList" :key="index" >
            <div class="match-date">{{level1.matchDate}}</div>
            <router-link :to="{path:`/${currentLocale}/${currentSportType}/${countryKey}/${leagueKey}/${level2.matchName}/${level2.matchId}/info`}" target='_blank' class="match-container" :class="{'live-selected' : level2.isLive}"  v-for="(level2, index) in level1.matchList" :key="index"
                  >
                <div class="flex-1"  v-for="(level3, index) in level2.opponents" :key="index">
                    <div class="display-flex-sb h-100" v-if="index === 0">
                        <div class="font-s">
                            <span :class="{'match-status__live': level2.matchStatus==='Live'}"> {{level2.matchStatus}}</span>                               
                        </div>
                        <div class=" match-detail">
                            <span class="mr-1rem match-detail__name">{{level3.name}}</span>
                                <img class="mr-1rem" v-if="level3.penaltyWin" src="../../static/images/label/label_pen_win.png">
                            <Icon :logoUrl=level3.logo o class="mr-2rem"></Icon> 
                            <div class="match-detail__score" >{{level3.score}}</div>	                            
                            <div class="match-middle__seperator">:</div>
                        </div>                           
                    </div> 
                    
                    <div class="display-flex-sb flex-1 h-100" v-if="index===1">
                        <div class="match-detail">
                            <div class="mr-2rem match-detail__score" >{{level3.score}}</div>	
                            <Icon  :logoUrl=level3.logo class="mr-1rem"></Icon>
                            <img class="mr-1rem" v-if="level3.penaltyWin" src="../../static/images/label/label_pen_win.png">
                            <span>{{level3.name}}</span>
                        </div>
                          <div v-if="level2.containLiveStream" class="home-match__icon-livevideo"  @click="handleHighlight(o, level2, $event)"></div>
                        <div v-else class="home-match__icon-highlight" :class="{'selected' : level2.containHighlight}" @click="handleHighlight(o, level2, $event)"></div> 
                        <!-- <div class="home-match__icon-highlight" :class="{'selected' : level2.containHighlight}" @click="handleHighlight(level2, $event)"></div> -->
                    </div>               
                </div>           
            </router-link>
        </div>
     </div>
    <div v-if="isMobile">
        <div class="match-wrapper " v-for="(level1, index) in matchList" :key="index"  v-show="!isLoading && !isShowNoData">
            <div class="match-date">{{level1.matchDate}}</div>
            <div class="home-match__container" >                
                <router-link :to="{path:`/${currentLocale}/${currentSportType}/${countryKey}/${leagueKey}/${level2.matchName}/${level2.matchId}/info`}" target='_blank'  class="home-match__card" :class="{'live-selected' : level2.isLive}" v-for="(level2, index) in level1.matchList" :key="index"
                    @click="$tools.openNewWindow($event)">            
                    <p class="home-match__time">{{level2.matchStatus}}</p>
                    <div class="display-flex-fe flex-1">
                        <div class="home-match__detail-wrapper">        
                            <div class="home-match__detail"  v-for="(level3, index) in level2.opponents" :key="index">
                                <div class="home-match__detail-opponent">
                                        <Icon :logoUrl=level3.logo  class="home-match__icon"></Icon> 
                                    <span>{{level3.name}}</span>  
                                    <img class="ml-1rem" v-if="level3.penaltyWin" src="../../static/images/label/label_pen_win.png">                                  
                                </div>                               
                                <div class="display-flex">
                                    <div class="home-match__detail-score" >{{level3.score}}</div>                                        
                                </div>                      
                            </div>
                        </div>
                      
                        <div v-if="level2.containLiveStream" class="home-match__icon-livevideo__mobile"  @click="handleHighlight(o, level2, $event)"></div>
                        <div v-else class="home-match__icon-highlight__mobile" :class="{'selected' : level2.containHighlight}" @click="handleHighlight(o, level2, $event)"></div> 
                        <!-- <div class="home-match__icon-highlight__mobile" :class="{'selected' : level2.containHighlight}" @click="handleHighlight(level2, $event)"></div> -->
                    </div>       
                </router-link>
            </div>
        </div>
    
    </div>
    <Loading v-show="isLoading"></Loading>
    <div class="no-data" v-show="isShowNoData && !isLoading">{{$t('NO_DATA')}}</div>
   
</template>

<script>
import {mapGetters,mapActions} from 'vuex'
import config from '@/js/config.js'
export default {
  props: {
   	params:{
		type:Object
	},
    params2:{
		type:Object
	},
  },
    watch:{
		params:{
			deep:true,
             immediate: true,
	        	handler(newVal,oldVal) {
                    if (JSON.stringify(newVal) !== JSON.stringify(oldVal) ) {
                        }
                        this.getFixtureByLeagueData(newVal);
                    
           				
			}
		},
        // params2:{
		// 	deep:true,
        //       immediate: true,
	    //     	handler(newVal,oldVal) {
        //             debugger;
        //           this.getFixtureByLeagueData(newVal);				
		// 	}
		// }
   },

	computed:{
           ...mapGetters([              
                'isMobile',
                'currentLocale',
                'currentSportType'
           ]),
            matchList(){ 
                let matchList = this.matchList;
                for (let level1 = 0; level1<matchList.length; level1++) {
                    for (let level2 = 0; level2<matchList[level1].matchList.length; level2++){
                        for (let level3 = 0; level3<matchList[level1].matchList[level2].opponents.length; level3++){
                            if (matchList[level1].matchList[level2].opponents[level3].score ===""){
                                matchList[level1].matchList[level2].opponents[level3].score = "-"
                            }
                        }
                    }
                }
                return matchList
            }
	},
   data() {
       return {
            s3ImgUrl:config.s3ImgUrl,
            isLoading: false,
            isShowNoData:false,

            matchList:[],
            countryKey: '',
            leagueKey: ''
       }
   },
   methods: {
         ...mapActions([
            "getFixtureByLeague",
        ]),
        async getFixtureByLeagueData(newVal){
			let params = newVal;
            if (typeof params === "undefined") {
                return
            }
           
            if (params.leagueKey === '') {
                return;
            }
            if (typeof params.type !== 'undefined') {
                  if (params.type === ""){
                    delete params.type;
                }
            }

           

            if (newVal.isCallInterval) {
                this.isLoading = false;
            } else {
                this.isLoading = true
            }
          
            //this.isShowNoData = false;
            
			const result = await this.getFixtureByLeague(params);
          
            this.isLoading = false;
          
            if (result.result !== null) {
                 if (result.result.matchDateList === null) {                
                    this.isShowNoData = true;              
                } else {
                    this.isShowNoData = false;

                    this.matchList = result.result.matchDateList;    
                    this.countryKey = result.result.countryKey;
                    this.leagueKey = result.result.leagueKey;             
                    this.filterLeagueList(this.matchList);                       
                }

            }
           
            
             this.$emit('changeMatchContentFixtureByLeagueEvent',result.result);
		},
         async getFixtureByLeagueDataInterval(newVal){
			let params = newVal;

            if (typeof params.type !== 'undefined') {
                if (params.type === ""){
                    delete params.type;
                }
            }

            this.isLoading = false;
            
			const result = await this.getFixtureByLeague(params);
          
            this.isLoading = false;

            if (result.result.matchDateList === null) {                
                this.isShowNoData = true;              
            } else {
                this.isShowNoData = false;

                this.matchList = result.result.matchDateList;    
                this.countryKey = result.result.countryKey;
                this.leagueKey = result.result.leagueKey;             
                this.filterLeagueList(this.matchList);                       
            }
            
             this.$emit('changeMatchContentFixtureByLeagueEvent',result.result);
		},
        
        filterLeagueList(matchList){
            //filter for matchList if score ==="", then show "-"   
            for (let level2 = 0; level2<matchList.length; level2++){
                for (let level3 = 0; level3<matchList[level2].matchList.length; level3++){
                    for (let level4 = 0; level4<matchList[level2].matchList[level3].opponents.length; level4++){
                        if (matchList[level2].matchList[level3].opponents[level4].score ===""){
                            matchList[level2].matchList[level3].opponents[level4].score = "-"
                        }
                    }
                }
            }          
        },
        handleMatchDetailRoutePath(o, level2, e){
            if (level2.containLiveStream) {
                e.preventDefault();
                e.stopPropagation();
                
                let link = `/${this.currentLocale}/${this.currentSportType}/${this.countryKey}/${this.leagueKey}/${level2.matchName}/${level2.matchId}`;
                window.open(link);
                return false
            } else {
                this.$tools.openNewWindow(e)
            }
        },
        handleHighlight(o, level2, e) {       
            if (level2.containLiveStream) {
                e.preventDefault();
                e.stopPropagation();
                let link = `/${this.currentLocale}/${this.currentSportType}/${this.countryKey}/${this.leagueKey}/${level2.matchName}/${level2.matchId}`;
            
                window.open(link);
                return false   
            }
            else if (level2.containHighlight) {
                e.preventDefault();
                e.stopPropagation();
                this.$router.push({ path: `/${this.currentLocale}/${this.currentSportType}/highlights/${this.countryKey}/${this.leagueKey}/${level2.matchName}/${level2.matchId}`, query: { date: level2.matchDate } })
                return false
            } else {
                e.preventDefault();
                e.stopPropagation();
                let link = `/${this.currentLocale}/${this.currentSportType}/${this.countryKey}/${this.leagueKey}/${level2.matchName}/${level2.matchId}`;
            
                window.open(link);
                return false
            }

        },

   },
}
</script>

<style scoped>
    /* .league-match__card{
        display: block;
    } */
    .home-match__win-lose{
        background-color: green;
        border-radius: 50%;
        padding: .3rem;
        color: white;
        width: .75rem;
        border: .1rem solid white;
        height: .75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: .5rem;
    }
    .home-match__win-lose.W{
        background-color: #5CD66C;    
    }
    .home-match__win-lose.L{
        background-color: #CF283E;    
    }
      .home-match__win-lose.D{
        background-color:   #ED9C5A;    
    }
    .match-wrapper{
        margin-bottom: 1.2rem;
    }
    .match-date {
        font-size: .88rem;
        margin-bottom: .3rem;
        margin-left: .2rem;
    }
    .match-container{
        display: flex;
        background-color: #212B30;
        color: #CFD9DE;
        margin-bottom: .5rem;
        padding: .6rem .63rem;
        border-radius: .5rem;
        font-size: .88rem;
        text-align: left;
        width: auto;
    }
    .match-container:hover{
        background-color: var(--bg-hover);
    }
    .match-container.live-selected{
        border-left: .4rem solid var(--bg-selected);
        padding-left: 0.5rem;
    }
	.match-status__live{
		background-color: #CF283E;
		padding: .2rem;
    	border-radius: .3rem;
		font-size:.65rem
	}
    .match-detail{
        display:flex;
        align-items: center;
        justify-content: center;
        height: 100%;
    }
    .match-detail__score{
        width: 1rem;
        text-align: center;
    }
    .match-detail__name{
        width: 8rem;
        overflow: hidden;
        text-align: right;
    }
    .match-middle{
        flex: 0.5;
        display: flex;
        justify-content: center;
        align-items: center;
        color:white;
    }
    .match-middle__seperator{
        margin: 0 .5rem;
        font-weight: bold;
        font-weight: bold;
    }
</style>