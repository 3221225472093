<template>
   <MobileHeader ></MobileHeader>

  <div class="home has-header">
    <Banner v-if="!isMobile"></Banner>
 
    <div class="container home-nav__containers mb-1rem has-space">
        <div v-if="isMobile" class="display-flex-align-center league-header__wrapper">
            <div>
                <Icon  :logoUrl=leagueInfo.logo  class="mr-05rem"></Icon>
            </div>
            <div>
                <h2 class="home-match__title home-match__title-mobile">{{$t(leagueInfo.leagueName)}}</h2>
                <p class="home-match__title-country" v-if="!!leagueInfo.leagueName">{{$t(leagueInfo.country)}}</p>
                <p class="home-match__title home-match__title-mobile" v-else>{{$t(leagueInfo.country)}}</p>
            </div>
        </div>
        <!--tabs display-->
		<div v-if="!isMobile" class="display-flex-align-center mt-s mb-main">
			<div class="mr-1rem">
                <Icon  :logoUrl=leagueInfo.logo  class="mr-05rem"></Icon>
			</div>
			<div>
				<h2 class="league-title">{{$t(leagueInfo.leagueName)}}</h2>
				<p class="league-title__country">{{$t(leagueInfo.country)}}</p>
			</div>
		</div>

        <div v-if="isMobile" class=" display-flex mb-1rem mt-m">
             <div class="home-nav__child overflow-x" :class="{'selected':o.selected}" v-for="(o, index) in tabs" :key="index" @click="selectTabs(o)">
                <span  class="home-nav__child-text nowrap">{{$t(o.name)}}</span>
                <div class="home-nav__child-indicator"></div>
            </div>
        </div>

        <div v-if="!isMobile" class=" display-flex mb-1rem mt-m">
            <div class="home-nav__child" :class="{'selected':o.selected}" v-for="(o, index) in primaryTabs" :key="index" @click="selectPrimaryTabs(o)">
                <span  class="home-nav__child-text">{{$t(o.name)}}</span>
                <div class="home-nav__child-indicator"></div>
            </div>
        </div>
        <!--content display-->
  		<div v-if="!isMobile" class="display-flex row-wrap">
            <div class="flex-2 mr-2rem">
                <div v-show="currentPrimaryTabs === 1">
                    <div class="nav-pill__wrapper mb-main display-flex">
                        <div class="relative" v-if="subLeagueList.length>1">
                            <div class="nav-pill" :class="{'selected' : currentSubLeague.colored}" @click="handleSubLeagueBtn($event)">
                                <span class="mr-1rem">{{$t(currentSubLeague.subLeagueName)}}</span>
                                <i class="svg-arrow__vertical "  :class="{'selected': currentSubLeague.colored}">
                                    <svg width="13" height="8" viewBox="0 0 13 8" class="league-dropdown__svg" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z" fill="inherit"/>
                                    </svg>
                                </i>
                            </div>
                                
                            <div class="dropdown-wrapper" :class="{'selected' : currentSubLeague.isShowDropdown}" >
                                <perfect-scrollbar>
                                    <div class="dropdown-container__wrapper">
                                        <div class="dropdown-container" v-for="(o,index) in subLeagueList" :key="index" @click="handleSubLeagueDropdown(o ,false)">
                                            <div class="dropdown-container__name">
                                                <span>{{$t(o.subLeagueName)}}</span>
                                            </div>
                                            <div class="dropdown-container__tick" :class="{'selected' : o.selected}"></div>
                                        </div>
                                    </div>
                                </perfect-scrollbar>
                            </div>                          
                        </div>
                        <div class="league-matchtabs__seperator"  v-if="subLeagueList.length>2"></div>          
                        <DatePicker v-model="montlyCalenderModel" color="#6F8C9B" is-dark :locale="currentLocale==='vn' ?'vi':currentLocale">
                            <template #default="{ inputValue, togglePopover, hidePopover }">
                                <div class="nav-pill"   :class="{'selected':montlyCalender.selected}" @click.stop="monthlyCalendarHandle($event, date, togglePopover,hidePopover )">
                                <span v-if="montlyCalender.date === 'DATE'"> {{$t(montlyCalender.date)}}</span> 
                                    <span v-else> {{montlyCalender.date}}</span>
                                </div>
                            </template>
                        </DatePicker>
                        <!-- <div v-show="isShowClearBtn" class="nav-pill"  @click="handleClearDate()">{{$t('CLEAR')}}</div>       -->
                        <div class="nav-pill" v-for="(o, index) in matchOptions" :key="index"   :class="{'selected':o.selected}" @click="handleMatchOptions(o)">{{$t(o.name)}}</div>            
                        <div><span class="league-matchtabs__reset" @click="handleReset()">{{$t('RESET')}}</span></div>
                    </div>  
                </div>
                <div v-show="currentPrimaryTabs === 2">
                    <div class="nav-pill__wrapper mb-main display-flex">
                        <div class="relative">
                            <div class="nav-pill" :class="{'selected' : currentSeasonList.colored}" @click="handleSeasonBtn($event)">
                                <span class="mr-1rem">{{currentSeasonList.name}}</span>
                                <i class="svg-arrow__vertical "  :class="{'selected': currentSeasonList.colored}">
                                    <svg width="13" height="8" viewBox="0 0 13 8" class="league-dropdown__svg" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z" fill="inherit"/>
                                    </svg>
                                </i>
                            </div>
                                
                            <div class="dropdown-wrapper" :class="{'selected' : currentSeasonList.isShowDropdown}" >
                                <perfect-scrollbar>
                                    <div class="dropdown-container__wrapper">
                                        <div class="dropdown-container" v-for="(o,index) in seasonList" :key="index" @click="handleSeasonDropdown(o ,false)">
                                            <div class="dropdown-container__name">
                                                <span>{{o.season}}</span>
                                            </div>
                                            <div class="dropdown-container__tick" :class="{'selected' : o.selected}"></div>
                                        </div>
                                    </div>
                                </perfect-scrollbar>
                            </div>                          
                        </div>
                    </div>
                </div>
                <FixtureLeagueContent v-if="currentPrimaryTabs === 1" class="match-content__wrapper mr-2rem" :params=leagueListParams @changeMatchContentFixtureByLeagueEvent="changeMatchContentFixtureByLeagueEvent($event)"></FixtureLeagueContent>
                <FixtureLeagueContent v-if="currentPrimaryTabs === 2" class="match-content__wrapper mr-2rem" :params=leagueListSeasonParams @changeMatchContentFixtureByLeagueEvent="changeMatchContentFixtureByLeagueEvent($event)"></FixtureLeagueContent>
            
            </div>
            <div class="flex-1" v-show="!isMobile">
                <div  v-show="!isShowNoDataTable">
                    <div class="match-table__title-wrapper" >
                        <div class="display-flex w-100">
                            <div class="match-table__title-arrow__wrapper tl" :class="{'invisible':!rankingTable.isShowArrow}" @click="handleLeagueListRankingTable('prev')">
                            <svg width="6" height="10" viewBox="0 0 6 10" class="match-table__title-arrow__icon"  xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.66542 1.74875L2.42125 5L5.66542 8.25125L4.66667 9.25L0.41667 5L4.66667 0.75L5.66542 1.74875Z" />
                                </svg>
                            </div>
                            <div class="match-table__title-country">
                                <div class="match-table__title-country-container" >
                                    <span>{{$t(rankingTable.currentSelectedLeague)}}</span>
                                </div>
                            </div>
                            <div class="match-table__title-arrow__wrapper" :class="{'invisible':!rankingTable.isShowArrow}" @click="handleLeagueListRankingTable('next')">
                                <svg width="6" height="10" viewBox="0 0 6 10"   class="match-table__title-arrow__icon" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M0.334961 8.46017L3.57913 5.20892L0.334961 1.95767L1.33371 0.958923L5.58371 5.20892L1.33371 9.45892L0.334961 8.46017Z" />
                                    </svg>
                                <!-- <img  v-show="rankingTable.isShowArrow" src="../../../static/images/icons/icon_arrow_right_white.png"> -->
                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="display-flex-sb match-table__header" >
                            <div>{{$t('TABLE')}}</div>
                            <div class="nav-pill__wrapper">
                                <div class="nav-pill" :class="{selected:o.selected}" v-for="(o, index) in tableTabs" :key="index" @click="selectTableTabs(o)">{{$t(o.name)}}</div>
                            </div>
                        </div>

                        <div v-show="currentTableTabs === 'total'">
                            <MatchTable :isShowTabs=false :type="'total'" :matchTableListData=matchTableList></MatchTable>
                        </div>
                        <div v-show="currentTableTabs === 'home'">
                            <MatchTable :isShowTabs=false :type="'home'" :matchTableListData=matchTableList></MatchTable>
                        </div>
                        <div v-show="currentTableTabs === 'away'">
                            <MatchTable :isShowTabs=false :type="'away'" :matchTableListData=matchTableList></MatchTable>
                        </div>
                    </div>
                </div>
                <div class="league-about__container" v-if="!isNoData">
                    <h2 class="league-about__title">{{$t('ABOUT')}}</h2>
                    <p class="league-about__content">{{aboutContent}}</p>                 
                </div>
            </div>
        </div>

        <div v-if="isMobile">
            <div v-show="currentTabs === 1">
                <div class="display-flex-sb match-table__header" >                                 
                      <div class="nav-pill__wrapper league-matchtabs__wrapper">
                        <div id="subLeagueId" v-if="subLeagueList.length>1" class="nav-pill" :class="{'selected' : currentSubLeague.colored}" >
                            <span id="subLeagueName" class="mr-1rem">{{$t(currentSubLeague.subLeagueName)}}</span>
                            <i class="svg-arrow__vertical "  :class="{selected: currentSubLeague.isShowDropdown}">
                                <svg width="13" height="8" viewBox="0 0 13 8" class="league-dropdown__svg" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z" fill="inherit"/>
                                </svg>
                            </i>
                        </div>
                         <div v-if="subLeagueList.length>1" class="league-matchtabs__seperator"></div> 
                         <DatePicker v-model="montlyCalenderModel" color="#6F8C9B" is-dark :locale="currentLocale==='vn' ?'vi':currentLocale">
                            <template #default="{ inputValue, togglePopover, hidePopover }">
                                <div class="nav-pill"   :class="{'selected':montlyCalender.selected}" @click.stop="monthlyCalendarHandle($event, date, togglePopover,hidePopover )">
                                    <span v-if="montlyCalender.date === 'DATE'"> {{$t(montlyCalender.date)}}</span> 
                                    <span v-else> {{montlyCalender.date}}</span>
                                </div>
                            </template>
                        </DatePicker>
                        <div class="nav-pill" v-for="(o, index) in matchOptions" :key="index"   :class="{'selected':o.selected}" @click="handleMatchOptions(o)">{{$t(o.name)}}</div>  
                         <div><span class="league-matchtabs__reset" @click="handleReset()">{{$t('RESET')}}</span></div>
                    </div>
                 </div>
                <div class="match-filter__date mb-main">                 
                   <!-- <div ><span class="match-filter__child" @click="handleClearDate()">{{$t('CLEAR')}}</span></div> -->
                </div>
                
                <FixtureLeagueContent  class="match-content__wrapper mr-2rem " :params=leagueListParams @changeMatchContentFixtureByLeagueEvent="changeMatchContentFixtureByLeagueEvent($event)"></FixtureLeagueContent>
            </div>
            <div v-if="currentTabs === 4">
                 <div class="display-flex-sb match-table__header" >                                 
                      <div class="nav-pill__wrapper league-matchtabs__wrapper">
                        <div id="seasonListId" class="nav-pill selected" :class="{'selected' : currentSeasonList.colored}" >
                            <span class="mr-1rem">{{currentSeasonList.name}}</span>
                            <i class="svg-arrow__vertical "  :class="{selected: currentSeasonList.isShowDropdown}">
                                <svg width="13" height="8" viewBox="0 0 13 8" class="league-dropdown__svg" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.74042 0.589996L6.53499 5.17L11.3296 0.589996L12.8024 2L6.53499 8L0.267578 2L1.74042 0.589996Z" fill="inherit"/>
                                </svg>
                            </i>
                        </div>
                      </div>
                 </div>
                <FixtureLeagueContent  class="match-content__wrapper mr-2rem" :params=leagueListSeasonParams @changeMatchContentFixtureByLeagueEvent="changeMatchContentFixtureByLeagueEvent($event)"></FixtureLeagueContent>
            </div>
            <div v-show="currentTabs === 2">  
                <div v-show = "!isShowNoDataTable">
                    <div class="match-table__title-wrapper" >
                        <div class="display-flex w-100">
                            <div class="match-table__title-arrow__wrapper tl" :class="{'invisible':!rankingTable.isShowArrow}" @click="handleLeagueListRankingTable('prev')">
                                <img  src="../../../static/images/icons/icon_arrow_left_white.png">
                            </div>
                            <div class="match-table__title-country">
                                <div class="match-table__title-country-container" >
                                    <span>{{rankingTable.currentSelectedLeague}}</span>
                                </div>
                            </div>
                            <div class="match-table__title-arrow__wrapper" :class="{'invisible':!rankingTable.isShowArrow}" @click="handleLeagueListRankingTable('next')">
                                <img  v-show="rankingTable.isShowArrow" src="../../../static/images/icons/icon_arrow_right_white.png">
                            </div>
                        </div>
                    </div>
                    <div class="display-flex-sb match-table__header" >                   
                        <div class="nav-pill__wrapper">
                            <div class="nav-pill" :class="{selected:o.selected}" v-for="(o, index) in tableTabs" :key="index" @click="selectTableTabs(o)">{{$t(o.name)}}</div>
                        </div>
                    </div>

                    <div v-show="currentTableTabs === 'total'">
                        <MatchTable :isShowTabs=false :type="'total'" :matchTableListData=matchTableList></MatchTable>
                    </div>
                    <div v-show="currentTableTabs === 'home'">
                        <MatchTable :isShowTabs=false :type="'home'" :matchTableListData=matchTableList></MatchTable>
                    </div>
                    <div v-show="currentTableTabs === 'away'">
                        <MatchTable :isShowTabs=false :type="'away'" :matchTableListData=matchTableList></MatchTable>
                    </div>   
                </div>    
                <div class="no-data" v-show = "isShowNoDataTable">{{$t('NO_DATA')}}</div>     
            </div>
             <div v-show="currentTabs === 3">  
                <div class="league-about__container" v-if="aboutContent">
                    <h2 class="league-about__title">{{$t('ABOUT')}}</h2>
                    <p class="league-about__content">{{aboutContent}}</p>
                </div>
                <div class="no-data" v-else >{{$t('NO_ABOUT')}}</div>
             </div>
        </div>
    </div>

  </div>

</template>

<script>
// @ is an alias to /src
import {mapGetters,mapActions} from 'vuex'
import moment from 'moment'
import MobileSelect from "@/lib/mobile-select";
import {DatePicker} from "@/lib/v-calendar";

import MobileHeader from '@/components/mobile/Header.vue'
import FixtureLeagueContent from '@/components/FixtureLeagueContent.vue'
import MatchTable from '@/components/match/Table.vue'
import Banner from '@/components/Banner.vue'
import config from '@/js/config.js'
import about from '@/js/about/league.js'


const DATE = 'DATE'
let hideToggleElement;
let hideToggleTarget
let mobileSelectSubLeague; // defined mobile select dropdown component for subLeague
let mobileSelectSeason // defined mobile select dropdown component for seson
export default {
    name: 'Home',
    components: {
		FixtureLeagueContent,
		MatchTable,
		MobileHeader,
		Banner,
        DatePicker
    },
    data() {
      return {
        displayDateFormat : config.displayDateFormat,
        apiParamDateFormat : config.apiParamDateFormat,
	    montlyCalender:{
            date : DATE, 
            dateValue :'',
            selected:false,
            isFirstime:false
        },
        montlyCalenderModel:'',
		selectedDate: {},//date

        isShowClearBtn:false,//date

		leagueInfo:{
            leagueName: '',
            country: ''
        },
        mobileHeaderTitle:'',
        mobileSubHeaderTitle:'',

        tabs:[ //mobile
            {
                name:'MATCHES',
                id:1,
                selected: true
            },
            {
                name:'PAST_SEASONS',
                id:4,
                selected:false 
            },
            {
                name:'TABLE',
                id:2,
                selected: false
            },
            {
                name:'ABOUT',
                id:3,
                selected:false
            },
        ],
        primaryTabs: [
            {
                name:'MATCHES',
                id:1,
                selected:true 
            },
            {
                name:'PAST_SEASONS',
                id:2,
                selected:false 
            }
        ],
        tableTabs:[
            {
                name:'ALL',
                id:'total',
                selected:true,
            },
            {
                name:'HOME',
                id:'home',
                selected:false,
            },
            {
                name:'AWAY',
                id:'away',
                selected:false,
            },
        ], 

        currentTabs: 1,//mobile default show first 1
        currentPrimaryTabs: 1,//mobile default show first 1
        currentTableTabs: 'total', //default show first 1

        matchTableList: [],
        isLoading: false,
        isShowNoDataTable: true,
        isNoData: true,

  		leagueListParams: {
            leagueKey:'',
            matchDate:''
        },

        rankingTable: {
            isShow:false,
            isShowArrow:true,
            isLoading:false,
            currentSelectedLeagueIndex:0,
            list:[],
            currentSelectedLeague:'',
        },
        
        aboutContent : '',

        matchOptions:[
            {
                name:'UPCOMING',
                selected: false,
                type: 1
            },
            {
                name:'MOST_RECENT',
                selected: false,
                type: 2
            },
        ],
        isFirstTimeGetFixtureLeagueData: true,

        fixtureByLeagueDataInterval: null,
        isCallMatchContentInterval: false,

        subLeagueList: [//subleague list
            {
                subLeagueName: 'ALL_GROUPS',
                subLeagueId: '',
                selected: false
            }
        ], 
        seasonList: [],
        currentSubLeague: {
            subLeagueName: '',
            isShowDropdown: false,
            colored: false,
        },
        currentSeasonList: {
            name: '',
            isShowDropdown: false,
            colored: false,
        },
        updateKey: false,
      }
    },
    watch:{
        $route() {
            if (this.montlyCalender.dateValue != "") {
                this.montlyCalender.date = moment(this.montlyCalender.dateValue).format(this.displayDateFormat); // when change multilanguage options translate the display date also
            }
        },
		montlyCalenderModel:{
			deep:true,
            handler(newVal,oldVal) {
        
                if (newVal !== DATE) {
                    if (typeof hideToggleElement !== "undefined") {
                        setTimeout(() => hideToggleElement({ ref: hideToggleTarget }), 500);// noob fix vcalendar bug (somethimes dont close the calendar automatic);
                    }
               
                    this.montlyCalender.date = moment(newVal===null ?oldVal:newVal).format(this.displayDateFormat);
                    this.montlyCalender.dateValue = newVal===null ? oldVal:newVal;
                    this.montlyCalenderModel = newVal===null ?oldVal:newVal;
                    
                    //this.isShowClearBtn = true;

                    this.montlyCalender.selected = true;
                    this.handleResetMatchOptions();

                    this.getFixtureByLeagueData();
                }
            }
		},
        currentLocale(newValue, oldValue) {
            this.selectPrimaryTabs();
        }
    },
	computed: {
        ...mapGetters([
            'isMobile',
            'currentLocale'
        ]),
       
    },
    created() {         
    
    },
    beforeUnmount() {
        clearInterval(this.fixtureByLeagueDataInterval)
    },
    mounted() { 
        this.leagueKey = this.$route.params.leagueKey;
        this.leagueId = this.$route.query.leagueId;
        this.subLeagueId = this.$route.query.subLeagueId;
        this.init();
        document.addEventListener('click', this.documentClick);
    },

    methods: {
		...mapActions([
			"getFixtureByLeague",
            "getSoccerLeagueRanking",
            "getSoccerSubLeagueList",
            "getSeasonListByLeagueId"
		]),
        init(){
            this.handleMatchOptions(this.matchOptions[0], true);// default call upcomming data, and get pass subLeagueId from querystring
            this.initGetFixtureByLeagueDataInterval();
            this.getAboutData();
            this.getSoccerSubLeagueListData();// get subleague data and bind to dropdown

            this.selectTabs(this.tabs[0]);
        },

        monthlyCalendarHandle (e, date, toggle, hideToggle) {
            toggle({ ref: e.target });

            hideToggleTarget = e.target;
            hideToggleElement =  hideToggle
        },

		dateSelected(e, date, toggle) {	
			this.selected = date;
			toggle({ ref: e.target });
		},

        handleClearDate(){
            this.montlyCalender.selected = false;
            this.isShowClearBtn = false;
            this.montlyCalender.date = DATE;
            this.montlyCalender.dateValue = '';
        },

        //(Mobile) handle tabs
        selectTabs(o) {
            if (this.isMobile) {
                this.tabs.forEach(x=>{
                    x.selected = false
                });

                o.selected = true;
                this.currentTabs = o.id;

                switch(o.id){
                    case 1://matches
                        this.getSoccerSubLeagueListData();
                        break;
                    case 2: 
                        this.getSoccerLeagueRankingData();
                        break;
                    case 4: //past seasons
                        this.getSeasonListByLeagueIdData();
                        break;    
                }
            }
         

        }, 

        selectPrimaryTabs(o){
            if (o) {

            } else {
                o = this.primaryTabs[0];
            }
            this.primaryTabs.forEach(x=>{
                x.selected = false
            });
            o.selected = true;
            this.currentPrimaryTabs =  o.id;
            switch(o.id){
                case 1://matches
                    this.handleReset();
                    break;
                case 2: //past seasons
                    this.getSeasonListByLeagueIdData();
                    break;    
            }
        },
        //get about data
        getAboutData(){
            if ( typeof about[this.currentLocale] !== "undefined") {
                this.aboutContent = about[this.currentLocale][this.leagueKey];
                if (typeof this.aboutContent === "undefined" || this.aboutContent === '') {
                    this.isNoData = true
                } else {
                    this.isNoData = false
                }
            } else {
                this.isNoData = true
            }
        },

        initGetFixtureByLeagueDataInterval(){ 
            if (this.fixtureByLeagueDataInterva) {
                clearInterval(this.fixtureByLeagueDataInterval);
            }
            this.fixtureByLeagueDataInterval = setInterval(() => {
                this.getFixtureByLeagueDataInterval();              
            }, config.intervalMatchList);        
        },

		getFixtureByLeagueDataInterval(){      
			 let selectedDate
            
			 if (this.montlyCalender.dateValue !== "" && this.montlyCalender.dateValue !== DATE) {
			 	 selectedDate = moment(this.montlyCalender.dateValue).format(this.apiParamDateFormat);
			 } else {
                 selectedDate = ""
             }
            
			let params = {
				leagueKey: this.leagueKey,
                type: this.type,
				matchDate: selectedDate,
                subLeagueId: this.currentSubLeague.subLeagueId,
				timeZone: this.$tools.getCurrentTimeZone(),
                isCallInterval: true
			}
            this.isCallMatchContentInterval = true;

			this.leagueListParams = params;
         
		},

		getFixtureByLeagueData(isGetSeasonData){      
          
			 let selectedDate;
			 if (this.montlyCalender.dateValue !== "" && this.montlyCalender.dateValue !== DATE) {
			 	 selectedDate = moment(this.montlyCalender.dateValue).format(this.apiParamDateFormat);
			 } else {
                 selectedDate = ""
             }
           
			let params = {
				leagueKey: this.leagueKey,
                type: this.type,
				matchDate: selectedDate,
				timeZone: this.$tools.getCurrentTimeZone(),
                subLeagueId: this.currentSubLeague.subLeagueId,
                isCallInterval: false
			}
            
            if (isGetSeasonData) {
                 params = {
                    leagueKey: this.leagueKey,
                    timeZone: this.$tools.getCurrentTimeZone(),
                    season: this.currentSeasonList.name,
                }
            }

            this.isCallMatchContentInterval = false;
            if (isGetSeasonData) {             
               this.leagueListSeasonParams = params;
            } else {          
                this.leagueListParams = params;  
            }
		},

		changeMatchContentFixtureByLeagueEvent(data){
        
            if (data !== null) {
                 if (data.matchDateList === null && this.isFirstTimeGetFixtureLeagueData) {
                    this.handleMatchOptions(this.matchOptions[1]);// Go to "Most Recent" when "Upcoming" is empty AS-71
                }
                this.isFirstTimeGetFixtureLeagueData = false;//reset FirstTimeGetFixtureLeagueData status

                this.leagueInfo = data;
                this.leagueInfo.leagueName = data.leagueName || '';

                this.mobileHeaderTitle = this.leagueInfo.leagueName;
                this.mobileSubHeaderTitle = this.leagueInfo.country;

                if (!this.isCallMatchContentInterval) {
                    this.getSoccerLeagueRankingData();
                }
            }
           
		},

        async getSoccerLeagueRankingData(){  
            this.isShowLoading = true;   

            let params = {
                leagueIdList : this.leagueId     
            } 

            const result = await this.getSoccerLeagueRanking(params);
            this.isShowLoading = false;

            if (result.result.length === 0) {
                this.isShowNoDataTable = true;                
            } else {
                this.isShowNoDataTable = false;
                this.rankingTable.list = result.result;  
                this.handleLeagueListRankingTable();
            }     
        }, 

        //get soccer subleague list
        async getSoccerSubLeagueListData(){  
        
            let mobileSelectData = [];
            let mobileObj = {
                id: '',
                value: ''
            }
            mobileObj.id = this.subLeagueList[0].subLeagueId;
            mobileObj.value =this.$i18n.t(this.subLeagueList[0].subLeagueName);
            mobileSelectData.push(mobileObj);

            let params = {
                leagueId : this.leagueId   
            }     
                     
            const result = await this.getSoccerSubLeagueList(params);
        
            if (result.result.length != 0) {
                this.subLeagueList.length = 1; //reset subLeagueList array and only keep first array;
                result.result.forEach( x=> {
                    // (Mobile) insert subleague data to mobile select
                    let mobileObj = {
                        id: '',
                        value: ''
                    }
                    mobileObj.id = x.subLeagueId;
                    mobileObj.value = x.subLeagueName;

                    mobileSelectData.push(mobileObj);
                    
                    x.selected = false;
     
                    // (Dekstop) insert subleague data to desktop select
                    this.subLeagueList.push(x);
                      
                    // selected data 
                    if (x.subLeagueId === this.subLeagueId) {    
                        let isFirstTimeLoadData = true;            
                        this.handleSubLeagueDropdown(x, isFirstTimeLoadData);
                    }              
                })
                
                //(Mobile) initial mobile subleague select
                if (this.isMobile) {
                    this.$nextTick(() => {
                     //store vue js this to that
                     let that = this;

                    // temporary solution for translate in js (idealy all translation word store in json file )
                    const i18nEnsureBtnText ={
                        en: 'Done',
                        vn: 'xong',
                        th: 'เสร็จสิ้น'
                    }

                    const i18nCancelBtnText ={
                        en: 'Cancel',
                        vn: 'hủy bỏ',
                        th: 'ยกเลิก'
                    }

                    const i18nTitle = {
                        en: 'Select group',
                        vn: 'Chọn nhóm',
                        th: 'เลือกกลุ่ม'
                    }

                     let triggerId = '#subLeagueId';
                     let title = i18nTitle[this.currentLocale];
                     let ensureBtnText = i18nEnsureBtnText[this.currentLocale];
                     let cancelBtnText = i18nCancelBtnText[this.currentLocale];
                     let ensureBtnColor = '#01c3f7';
                     let titleBgColor = "#F0F0F0";
                    
                        

                     mobileSelectSubLeague = new MobileSelect({
                        trigger: triggerId,
                        triggerDisplayData: false,
                        title: title,
                        ensureBtnText: ensureBtnText,
                        cancelBtnText: cancelBtnText,
                        ensureBtnColor: ensureBtnColor,
                        titleBgColor: titleBgColor,

                        wheels: [
                            { 
                                data: mobileSelectData,
                            },
                        ],
                    
                        callback: function (indexArr, data) {              
                            that.currentSubLeague.subLeagueName = data[0].value;                       
                            that.currentSubLeague.subLeagueId = data[0].id;
                       
                            if (that.currentSubLeague.subLeagueId === '') {
                                that.currentSubLeague.colored = false;
                            } else {
                                that.currentSubLeague.colored = true;
                            }

                            that.getFixtureByLeagueData();                        
                        },
                    });
                
                     if (this.subLeagueId === null || typeof this.subLeagueId === 'undefined') {
                         mobileSelectSubLeague.locatePosition(0,0)
                     } else {
                         for (let i=0; i<mobileSelectData.length; i++) {
                             if (mobileSelectData[i].id === this.subLeagueId) {    

                                that.currentSubLeague.subLeagueName =  mobileSelectData[i].value;   
                                that.currentSubLeague.colored = true;     

                                mobileSelectSubLeague.locatePosition(0,i)   
                             }
                         }
                     }                   
                });
                }

            } else {
                let isFirstTimeLoadData = true;            
                this.handleSubLeagueDropdown(this.subLeagueList[0], isFirstTimeLoadData);
            }

            //(Desktop) if query string subleagueid is empty default pass 'All Groups' data to getFixturebyleague  
            if (!this.isMobile) {
                 if (this.subLeagueId === null || typeof this.subLeagueId === 'undefined') {
                    let isFirstTime = true;
                    this.handleSubLeagueDropdown(this.subLeagueList[0], isFirstTime);                
                }
            } else {
                 this.currentSubLeague.subLeagueName = mobileSelectData[0].value; 
            }     
           
        }, 

        //get getSeasonListData
        async getSeasonListByLeagueIdData(){
            let params = {
                leagueId : this.leagueId   
            }  

             let mobileSelectData = [];

            const result = await this.getSeasonListByLeagueId(params);

               result.result.forEach( x=> {
                     x.selected = false;

                    // (Mobile) insert subleague data to mobile select
                    let mobileObj = {
                        id: '',
                        value: ''
                    }
                    mobileObj.id = x.season;
                    mobileObj.value = x.season;

                    mobileSelectData.push(mobileObj);
               });

                if (this.isMobile) {
                    //(Mobile) initial mobile subleague select
                    this.$nextTick(() => {
                     //store vue js this to that
                     let that = this;

                    // temporary solution for translate in js (idealy all translation word store in json file )
                    const i18nEnsureBtnText ={
                        en: 'Done',
                        vn: 'xong',
                        th: 'เสร็จสิ้น'
                    }

                    const i18nCancelBtnText ={
                        en: 'Cancel',
                        vn: 'hủy bỏ',
                        th: 'ยกเลิก'
                    }

                    const i18nTitle = {
                        en: 'Select season',
                        vn: 'Chọn phần',
                        th: 'เลือกซีซั่น'
                    }

                     let triggerId = '#seasonListId';
                     let title = i18nTitle[this.currentLocale];
                     let ensureBtnText = i18nEnsureBtnText[this.currentLocale];
                     let cancelBtnText = i18nCancelBtnText[this.currentLocale];
                     let ensureBtnColor = '#01c3f7';
                     let titleBgColor = "#F0F0F0";
                    
                        

                     mobileSelectSeason = new MobileSelect({
                        trigger: triggerId,
                        triggerDisplayData: false,
                        title: title,
                        ensureBtnText: ensureBtnText,
                        cancelBtnText: cancelBtnText,
                        ensureBtnColor: ensureBtnColor,
                        titleBgColor: titleBgColor,

                        wheels: [
                            { 
                                data: mobileSelectData,
                            },
                        ],
                    
                        callback: function (indexArr, data) {    

                            that.currentSeasonList.name = data[0].value;                       
                            that.currentSeasonList.subLeagueId = data[0].id;
                       
                

                            let isGetSeasonData = true;
                            that.getFixtureByLeagueData(isGetSeasonData);                    
                        },
                    });

                    //default first option value
                    this.currentSeasonList.name = mobileSelectData[0].value
                    mobileSelectSeason.locatePosition(0,0);
                    
                    let isGetSeasonData = true;
               
                    this.getFixtureByLeagueData(isGetSeasonData);
                            
                });
                }
            this.seasonList = result.result;
            this.handleSeasonDropdown(this.seasonList[0])
        },

        selectTableTabs(o) {
            this.tableTabs.forEach(x=>{
                x.selected = false
            });
            o.selected = true;

            this.currentTableTabs = o.id;
        }, 

        //ranking table
        handleLeagueListRankingTable(action){
            let limit = this.rankingTable.list.length - 1;
            let index = 0;
            if (limit === 0) {
                this.rankingTable.isShowArrow = false;
            } else {
                this.rankingTable.isShowArrow = true;
            }

            if (action==="next") {
                if(this.rankingTable.currentSelectedLeagueIndex<limit) {
                    this.rankingTable.currentSelectedLeagueIndex ++;
                } else {
                    this.rankingTable.currentSelectedLeagueIndex = 0;
                }
                index = this.rankingTable.currentSelectedLeagueIndex;
            } else if(action==="prev") {
                if(this.rankingTable.currentSelectedLeagueIndex>0) {
                    this.rankingTable.currentSelectedLeagueIndex --;
                } else {
                    this.rankingTable.currentSelectedLeagueIndex = limit 
                }

                index = this.rankingTable.currentSelectedLeagueIndex;
            } else {
                index = 0;
            }
    
            this.rankingTable.currentSelectedLeague = this.rankingTable.list[index].tableName;
            this.matchTableList =this.rankingTable.list[index];
        },

        handleMatchOptions(o, isFirstTimeLoadData){
            this.matchOptions.forEach(x => {
                x.selected = false;
            })

            o.selected =  true;
            this.type = o.type;
            
            this.handleClearDate();

            if (isFirstTimeLoadData) {
                this.currentSubLeague.subLeagueId = this.subLeagueId;
            }
            this.getFixtureByLeagueData();
        },

        handleResetMatchOptions(){
            this.matchOptions.forEach(x => {
                x.selected = false;
            })
             this.type = "";
        },

        //(Desktop) subLeague dropdown select event
        handleSubLeagueDropdown(o, isFirstime){
            if (this.isMobile) {
                return;
            }
            this.subLeagueList.forEach(x => {
                x.selected = false;
            })
            o.selected = true;

            this.currentSubLeague.subLeagueName = o.subLeagueName;
            this.currentSubLeague.isShowDropdown = false;
            this.currentSubLeague.subLeagueId = o.subLeagueId;

            if (this.currentSubLeague.subLeagueId === '') {
                this.currentSubLeague.colored = false;
            } else {
                this.currentSubLeague.colored = true;
            }

            //first time initial load dont call getFixtureByLeagueData
            if (!isFirstime){
                this.getFixtureByLeagueData();
            }
            
        },

        //(Desktop) Season dropdown select event
        handleSeasonDropdown(o){
            if (this.isMobile) {
                return;
            }
            this.seasonList.forEach(x => {
                x.selected = false;
            })
            o.selected = true;
     
            this.currentSeasonList.name = o.season;
            this.currentSeasonList.isShowDropdown = false;
            this.currentSeasonList.colored = true;
            // this.currentSeasonList.subLeagueId = o.subLeagueId;

            // if (this.currentSeasonList.subLeagueId === '') {
            //     this.currentSeasonList.colored = false;
            // } else {
            //     this.currentSeasonList.colored = true;
            // }

            let isGetSeasonData = true;
   
            this.getFixtureByLeagueData(isGetSeasonData);
            
            
        },
        handleSubLeagueBtn(e){
            this.currentSubLeague.isShowDropdown = !this.currentSubLeague.isShowDropdown;
            e.stopPropagation();
        },

        handleSeasonBtn(e){
            this.currentSeasonList.isShowDropdown = !this.currentSeasonList.isShowDropdown;
            e.stopPropagation();
        },
        
        documentClick(e){ //when click outsite document, the subleague dropdown will close
            this.currentSubLeague.isShowDropdown = false;
            this.currentSeasonList.isShowDropdown = false;
            e.stopPropagation();
        },

        handleReset(){
   
            if (this.isMobile) {
                 this.currentSubLeague.subLeagueName =  this.subLeagueList[0].subLeagueName;  
                    this.currentSubLeague.subLeagueId = this.subLeagueList[0].subLeagueId;
                      
                    if (this.currentSubLeague.subLeagueId === '') {
                        this.currentSubLeague.colored = false;
                    } else {
                        this.currentSubLeague.colored = true;
                    } 
                    if (typeof mobileSelectSubLeague !== 'undefined') {
                        mobileSelectSubLeague.locatePosition(0,0) 
                    }
            } else {
                 this.handleSubLeagueDropdown(this.subLeagueList[0], true); 
            }
            this.isFirstTimeGetFixtureLeagueData = true;
            this.handleMatchOptions(this.matchOptions[0]);
            // for (let i = 0; i < this.subLeagueList.length; i++) {
            //     if (this.subLeagueList[i].subLeagueId === parseInt(this.subLeagueId) ) {    
            //           this.handleSubLeagueDropdown(this.subLeagueList[i]);

            //           //mobile select
            //             this.currentSubLeague.subLeagueName =  this.subLeagueList[i].subLeagueName;  
            //             this.currentSubLeague.subLeagueId = this.subLeagueList[i].subLeagueId;
                        
            //             if (this.currentSubLeague.subLeagueId === '') {
            //                 this.currentSubLeague.colored = false;
            //             } else {
            //                 this.currentSubLeague.colored = true;
            //             } 
            //             if (this.isMobile) {
            //                 mobileSelectSubLeague.locatePosition(0,i);
            //             }
            //            break;  
            //     }
            // }
            //  if (this.subLeagueId === null || typeof this.subLeagueId === 'undefined') {
            //      if (!this.isMobile) {
            //         let isFirstTime = true;
            //         this.handleSubLeagueDropdown(this.subLeagueList[0], isFirstTime);  
            //      } else {
            //         //mobile select
            //         this.currentSubLeague.subLeagueName =  this.subLeagueList[0].subLeagueName;  
            //         this.currentSubLeague.subLeagueId = this.subLeagueList[0].subLeagueId;
                      
            //         if (this.currentSubLeague.subLeagueId === '') {
            //             this.currentSubLeague.colored = false;
            //         } else {
            //             this.currentSubLeague.colored = true;
            //         } 
            //         if (this.isMobile) {
            //             mobileSelectSubLeague.locatePosition(0,0) 
            //         }
            //      }
                              
            // }
          
        }
    },
}
</script>
<style scoped>
	.league-title {
		font-size:2.125rem;
		margin:0
	}
	.league-title__country {
		font-size:1.375rem
	}
    .league-about__container{
        padding:1rem
    }
    .league-about__title{
        font-size: .88rem;
        margin-bottom: .5rem;
    }
    .league-about__content{
        font-size: .7rem;
        line-height: 1rem;
        color:var(--color-grey-light)
    }
    .league-matchtabs__wrapper{
        overflow-x: auto;
    }
    .league-matchtabs__seperator{
        width: 0.1rem;
        height: 1.4rem;
        background-color: #FFFFFF;
        margin: .1rem .5rem 0rem 0.2rem;
    }
    .league-matchtabs__seperator::after{
        content:'';
         width: 0.1rem;
        height: 1.4rem;
        background-color: #FFFFFF;
        margin: .1rem .5rem 0rem 0.2rem;
    }
    .league-matchtabs__reset{
        cursor: pointer;
        font-size: 0.875rem;
        font-weight: 700;
        color: var(--bg-selected)
    }
    .league-dropdown__svg{
        fill: white;
    }
    .league-header__wrapper{
        margin: 0.5rem 0 1.5rem;
    }
    .selected  .league-dropdown__svg{
        fill: black;
    }
    .match-table__header {
        margin: 1.1rem 0 0rem;
    }
    .match-filter{
        display: flex;

        margin: 1rem 0;
    }
    .match-filter__child{
        color:white;
        border: 1px solid white;
        border-radius: 1rem;
        padding: .4rem 1.1rem;
        margin-right: 1rem;
        cursor: pointer;
        font-size: .9rem;
    }
   
     .match-table__title-wrapper{
         display: flex;
         justify-content: space-between;
         font-size: .88rem;
         margin-bottom: .5rem;
     }
     .match-table__title-country{
        display: flex;
        width: calc(100% - 4rem);
        line-height: 1rem;
     }
     .match-table__title-country-container{
        transition: all .3s;
        min-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: .7rem;
        text-align: center;
     }
    .match-table__title-arrow__wrapper{
        width: 2rem;
        text-align: center;
        cursor: pointer;
        fill: white;
    }
    .match-table__title-arrow__wrapper:hover .match-table__title-arrow__icon{
        fill: var(--color-hover);
    }
    .match-table__title-arrow__wrapper.tl{
        /* text-align: left; */
    }
    @media (max-width: 768px){
         .league-about__container{
            padding: 0;
        }
    }

</style>
